Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DataStorage";
exports.labelBodyText = "DataStorage Body";

exports.enterTitle = "Enter title";
exports.enterDescription = "Enter description";
exports.enterDocType = "Enter document type";
exports.delete = "Enter document type";
exports.download = "Download";

exports.btnExampleTitle = "Upload";
exports.btnUploadTitle = "Select File";

exports.uploadAPIEndpoint= "bx_block_datastorage/file_documents";
exports.uploadAPIMethod = "POST";

exports.getLoggedUserDetailsEndPoint = 'bx_block_datastorage/file_documents';
exports.getLoggedUserDetailsMethod = "GET";

exports.deleteAFileEndpoint ="bx_block_datastorage/file_documents/";
exports.deleteAPIMethod ="DELETE"
// Customizable Area End