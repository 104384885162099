import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
 Select, MenuItem, Backdrop, FormControl, Paper , Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import { Delete, GetApp } from '@material-ui/icons';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DataStorageController, {
  Props,
  configJSON,
} from "./DataStorageController.web";

export default class DataStorage extends DataStorageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
    <div >
      <Paper style={{padding:"30px"}}>
        <Typography variant="h6">Upload a document</Typography>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                data-test-id="data-test"
                id="title"
                type="text"
                label="Title"
                 value={this.state.title}
                onChange={this.handleTitleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
               data-test-id="desc-test"
                id="description"
                label="Description"
                type="text"
                multiline
        
                value={this.state.description}
                onChange={this.handleDescChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl >
                <InputLabel id="document-type-label">Document Type</InputLabel>
                <Select
                data-test-id="file-test"
                style={{width:"9.5rem"}}
                  labelId="document-type-label"
                  id="document-type"
                  value={this.state.file_type}
                   onChange={event=>this.handleDocumentTypeChange(event as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)}
                >
                  <MenuItem value={"achievement"}>Achievement</MenuItem>
                  <MenuItem value={"certificate"}>Certificate</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <input
                accept="*"
                data-test-id="document-file-upload"
                multiple
                type="file"
                onChange={this.handleFileUpload}
              />
        </Grid>
        <Grid item xs={12}>
           <Button data-test-id="upload-test" style={{color:"aliceblue", backgroundColor:"#3f51b5"}}color="primary" onClick={this.uploadUserData}>
            Submit
           </Button>
        </Grid>
      </Grid>
    </form>
  </Paper>
<Modal open={this.state.model}  data-test-id={"close-test"} onClose={this.handleClose} closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    style: { backgroundColor: 'white', opacity: 1 }
  }} ><div>
     <Paper style={{ maxHeight: '80vh', overflow: 'auto' }}>
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>File Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {this.state.doc_list.map((item: {
              id: string;
              attributes: {
                title: string, 
                document_type: string | null, 
                description: string, 
                attachments: {file: string}[]
              }}, index : number)=>(
              <TableRow key={index}>
                <TableCell>{item.attributes.title}</TableCell>
                <TableCell> {item.attributes.description}</TableCell>
                <TableCell>{item.attributes.document_type}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="download"
                    href={`${configJSON.baseURl}${item.attributes.attachments[0].file}`}
                    download
                  >
                    <GetApp />
                  </IconButton>
                  <IconButton
                  data-test-id={"download-test"}
                  aria-label="delete"
                  onClick={() => this.handleDeleteDocument(item.id,index)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </Paper>
  </div> 
 </Modal>
</div>
  // Customizable Area End
);
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
